export default () => ({
  contactId: "",
  fuente: "",
  nombre: "",
  telefono: "",
  email: "",
  ciudad: "",
  asunto: "",
  estado: "",
  resultado: "",
  nota: "",
  labels: [],
});
